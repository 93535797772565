import React, { Suspense, useState, useEffect } from 'react';
import './App.css';
import CSS from 'csstype';
import useLocalStorage from 'use-local-storage';
import './i18n'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// images
//import skaLogo from './icons/logo-SKA-small.png';
import skaoLogo from './icons/skao-logo.1270.png';
import srcLogo from './icons/srcnet-dark.svg';
import srcLightLogo from './icons/srcnet-light.svg';
//import tangerineLogo from './icons/tangerine-combined-scaled.png';
import folderIcon from './icons/folder.256.png';
import workflowIcon from './icons/workflow.512.png';
import jupyterIcon from './icons/jupyter.380.png';
import terminalIcon from './icons/terminal.256.png';
import homeIcon from './icons/home.614.png';
import magnifierIcon from './icons/magnifier.512.png';
import chartIcon from './icons/chart.512.png';
import monitorIcon from './icons/monitor.1600.png';
import planetIcon from './icons/planet.512.png';
import dishIcon from './icons/dish.512.png';
import galaxyIcon from './icons/galaxy.512.png';
//import homeWhiteIcon from './icons/home-white.512.png';
//import folderWhiteIcon from './icons/folder-white.512.png';
//import personWhiteIcon from './icons/person-white.512.png';
//import magnifierWhiteIcon from './icons/magnifier-white.512.png';
import lightdarkwhiteIcon from './icons/lightdark-white.png';
import lightdarkblackIcon from './icons/lightdark-black.png';

// flag
import usukFlag from "./icons/US-UK-flag.png";
import dutchFlag from "./icons/Netherlands-flag.png";

// types
import { AccessToken } from './types';
import { JobType } from './types';

// types relating to tasks.
import { RefreshDirective } from './tasks';
import { TaskType } from './tasks';
import { UserSettings } from './tasks';
import { UserServiceToken } from './tasks';
import { MoveDataToStorage } from './tasks';
import { CurrentTask } from './tasks';
import { TaskDirective } from './tasks';

// functions
import { APIPrefix, GetURL, hasSessionExpired } from './functions';
import { StripDoubleQuotes } from './functions';

// classes
import { SearchResultsType } from './search-catalog/search-catalog';
import Home from './home/home';
import SearchCatalog from './search-catalog/search-catalog';
import Tool from './tools/tool';
import UserControl from './user-control/user-control';
import ProjectButton from './project-button';
import UserControlOIDCToken from './user-control/user-control-oidc-token';
import { HideDropdownMenu } from './functions';
import SearchCompute from './search-compute/search-compute';
import DataManagement from './data-management/data-management';
import Visualisation from './visualisation/visualisation';
import UserManagement from './user-management/user-management';
import Preferences from './user-preferences/preferences';
import SessionToast from './gateway-toast/session-toast';

// LEDA 2605481

//	--------------------------------------------------------------------------
//
//	C O N S T A N T S
//
//	--------------------------------------------------------------------------

const TITLE = 'SRC-NET Prototype';
	
// language.
const LANGUAGE_OPTIONS: { value: string, label: string, icon: any }[] = [	{ value: "en", label: "English", icon: usukFlag },
										{ value: "nl", label: "Nederlands", icon: dutchFlag } ];

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

enum ToolType
{
	HOME,
	SEARCH_CATALOG,
	SEARCH_COMPUTE,
	DATA_MANAGEMENT,
	NOTEBOOK,
	VISUALISE_DATA,
	USER_MANAGEMENT,
	WORKFLOW,
	MY_FILES,
	TERMINAL,
	VIRTUAL_MACHINE,
	OIDC_TOKENS
}

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

// ------------------------------------------------------------
//
//	This dropdown mimics a droplist listbox, but
//	allows an image to be displayed next to each list item.
//
// ------------------------------------------------------------

function LanguageDropdownMenu( args:	{
					sLanguageDropdownMenuDisplayed: boolean,
					setLanguageDropdownMenuDisplayed: any,
					buttonHandler: any
					} )
{

	// function that monitors for mouse clicks. we need to add {ref} to the DIV element of the dropdown menu.
	const { ref } = HideDropdownMenu(	{
						sDropdownDisplayed: args.sLanguageDropdownMenuDisplayed,
						setDropdownDisplayed: args.setLanguageDropdownMenuDisplayed
						} );

	//	------------------------------------------------------------
	//
	//	A HTML component that renders a single menu item on the
	//	dropdown menu.
	//
	//	------------------------------------------------------------

	function MenuItem( args:	{
					name: string,
					text: string,
					icon?: string,
					onClick: any
					} )
	{

		return	(
		
			<button name = {'btnLanguage_' + args.name} className = "dropdown-list-item" onClick = {args.onClick}>
				<div className = "dropdown-list-item-text">{args.text}</div>
				<div className = "dropdown-list-item-image">
					<img src = {args.icon} alt = "" width = "30" height = "16" />
				</div>
			</button>
		
			)

	} // MenuItem

	return	(

		<div	ref = {ref}
			style =	{	{
					display: (args.sLanguageDropdownMenuDisplayed === true ? 'block' : 'none'),
					position: 'absolute',
					top: '100%',
					right: '0%',
					border: 'none',
					zIndex: '9'
					} } >
				
			<div className = "language-dropdown-menu">
				{
				LANGUAGE_OPTIONS.map
				(
					(item, index) =>
					(
						<MenuItem	key = {index}
								name = {item.value}
								text = {item.label}
								icon = {item.icon}
								onClick = {args.buttonHandler} />
					)
				)
				}
			</div>
			
		</div>
			
		)

} // LanguageDropdownMenu

// ------------------------------------------------------------
//
//	Small icons that appear at the top of the screen to
//	allow the user to quickly navigate the portal.
//
// ------------------------------------------------------------

function NavigationIcon( args:	{
					icon: string,
					tooltip: string,
					name: string,
					onClick: any
					} )
{

	return	(
		<button	className = "navigation-icon"
				name = {args.name}
				onClick = {args.onClick}
				title = {args.tooltip}>
				
			<img	src = {args.icon}
				alt = ""
				width ="24"
				height = "24" />
				
		</button>
		)
	
} // NavigationIcon

//	--------------------------------------------------------------------------
//
//	M A I N   A P P L I C A T I O N
//
//	--------------------------------------------------------------------------

function App()
{

	// for some reason I need to put these style properties here, because when I put them in the CSS it doesn't work properly!
  	const projectSpace: CSS.Properties =
  			{
  			width: '100%',
  			height: '100%',
  			display: 'flex',
  			flexDirection: 'row',
  			flex: '1 1 0px',
  			marginBottom: '10px'
  			}

	// multi-language support
	const { i18n, t } = useTranslation();
	
	// get default user preferences..
	const DEFAULT_DARK = window.matchMedia( '(prefers-color-scheme: dark)' ).matches;
	const DEFAULT_LANGUAGE = getBrowserDefaultLanguage( { defaultLang: 'en' } );
	
	// define an empty token.
	const EMPTY_TOKEN: AccessToken = { access_token: "", token_type: "", refresh_token: "", expires_in: 0, scope: "", id_token: "", expires_at: 0 };
				
	// define the toolset available along the top of the page.
	const TOOLS:	{
			name: string,
			icon: any,
			text: string,
			toolType: ToolType,
			needSiteCapabilities?: boolean,
			needDataManagement?: boolean,
			needLoggedIn?: boolean,
			needJupyterURL?: boolean
			}[] =	[	{
					name: 'btnHome',
					icon: {homeIcon},
					text: 'Home',
					toolType: ToolType.HOME
					},
					{
					name: 'btnSearchCatalog',
					icon: {magnifierIcon},
					text: 'Search catalogue',
					toolType: ToolType.SEARCH_CATALOG
					},
					{
					name: 'btnSearchCompute',
					icon: {monitorIcon},
					text: 'Search compute resources',
					toolType: ToolType.SEARCH_COMPUTE,
					needSiteCapabilities: true,
					needLoggedIn: true
					},
					{
					name: 'btnDataManagement',
					icon: {monitorIcon},
					text: 'Data management',
					toolType: ToolType.DATA_MANAGEMENT,
					needDataManagement: true,
					needLoggedIn: true
					},
					{
					name: 'btnNotebook',
					icon: {jupyterIcon},
					text: 'Notebook',
					toolType: ToolType.NOTEBOOK,
					needJupyterURL: true
					},
					{
					name: 'btnVisualiseData',
					icon: {chartIcon},
					text: 'Visualise data',
					toolType: ToolType.VISUALISE_DATA
					}/*,
					{
					name: 'btnUserManagement',
					icon: undefined,
					text: 'User management',
					toolType: ToolType.USER_MANAGEMENT,
					needLoggedIn: true
					},
					{
					name: 'btnWorkflow',
					icon: {workflowIcon},
					text: 'Workflow',
					toolType: ToolType.WORKFLOW
					},
					{
					name: 'btnMyFiles',
					icon: {folderIcon},
					text: 'Files',
					toolType: ToolType.MY_FILES
					},
					{
					name: 'btnTerminal',
					icon: {terminalIcon},
					text: 'Terminal',
					toolType: ToolType.TERMINAL
					},
					{
					name: 'btnVMs',
					icon: {monitorIcon},
					text: 'VMs',
					toolType: ToolType.VIRTUAL_MACHINE
					}*/ ];

	// set a page title using a hook.
	useEffect( () => { document.title = TITLE; }, [] );
		  	
  	// if we've been returned from logging into IAM then get the authorisation code.
	const queryParameters = new URLSearchParams( window.location.search );
	const authorisationCode = queryParameters.get( "code" );
	
	//	-------------------------------------------------
  	//		
  	//	State variables
  	//
	//	-------------------------------------------------

  	// general:
  	const [sSelectedProject, setSelectedProject] = useState<string>( '' );
  	const [sSelectedTool, setSelectedTool] = useState<ToolType>( ToolType.HOME );
	
	// re-direct uri.
	const [sRedirectURI, setRedirectURI] = useState<string>( '' );
	
	// language:
	const [sLanguageDropdownMenuDisplayed, setLanguageDropdownMenuDisplayed] = useState<boolean>( false );
	const [sLanguage, setLanguage] = useLocalStorage( 'gateway_language', DEFAULT_LANGUAGE );
	const [sLanguageIndex, setLanguageIndex] = useState<number>( findLanguageIndex( { language: StripDoubleQuotes( { value: localStorage.getItem( 'gateway_language' ) } ) } ) );
  	
  	// light/dark mode theme.
	const [sTheme, setTheme] = useLocalStorage( 'gateway_theme', DEFAULT_DARK ? 'dark' : 'light' );
  	
  	// user control:
  	const [sUserDropdownMenuDisplayed, setUserDropdownMenuDisplayed] = useState<boolean>( false );
  	const [sUsername, setUsername] = useState<string>( authorisationCode === null ? i18n.t( 'Unknown User' ) : i18n.t( 'Logging in' ) + '...' );
	const [sPreferredUsername, setPreferredUsername] = useState<string>('');
  	
  	// notebook tool:
	const [sJupyterBaseURL, setJupyterBaseURL] = useState<string>( '' );
  	const [sJupyterURL, setJupyterURL] = useState<string>( '' );
  	const [sJupyterRender, setJupyterRender] = useState<number>( 0 );
	const [xsrfToken, setXsrfToken] = useState<string | null>(null);
	const [gatewayToken, setgatewayToken] = useState<string | null>(null);
  	
  	// visualisation tool:
  	const [sSearchPosition, setSearchPosition] = useState< { id: string, ra: number, dec: number }[]>( [] );
  	
  	// access token for OIDC.
  	const [sLoggedIn, setLoggedIn] = useState<boolean | undefined>( undefined );
	const [sSessionValid, setSessionValid] = useState< boolean | undefined >( undefined );
  	
  	// token refresh:
  	// if a token refesh is required then sRefreshTokens will be set to sTokenRefreshCount + 1. a hook will pick up the change of state and 
  	// perform the token refresh. once the refresh has completed then sTokenRefreshCount is set to sRefreshTokens. this process is designed so that
  	// when multiple refresh events are triggered simultaneously the system will not try to refresh the tokens multiple times.
  	const [sTokenRefreshCount, setTokenRefreshCount] = useState< number >( 0 );
  	const [sRefreshTokens, setRefreshTokens] = useState< number >( 0 );
  	
  	const [sCurrentTask, setCurrentTask] = useState< CurrentTask >( { taskType: TaskType.NONE } );
  	
  	// individual token refresh counts. when these values change they trigger some state variables to be refreshed from fresh requests.
  	const [sSiteCapabilitiesTokenRefreshCount, setSiteCapabilitiesTokenRefreshCount] = useState<number>( 0 );
  	const [sDataManagementTokenRefreshCount, setDataManagementTokenRefreshCount] = useState<number>( 0 );
  	const [sGatewayBackendTokenRefreshCount, setGatewayBackendTokenRefreshCount] = useState<number>( 0 );
  	
  	// tokens obtained
  	const [sSiteCapabilitiesTokenObtained, setSiteCapabilitiesTokenObtained] = useState<boolean>( false );
  	const [sDataManagementTokenObtained, setDataManagementTokenObtained] = useState<boolean>( false );
  	const [sGatewayBackendTokenObtained, setGatewayBackendTokenObtained] = useState<boolean>( false );

  	// loading tokens
  	const [sLoadingToken, setLoadingToken] = useState< boolean >( true );

	// Dialog boxes
	const [sShowPreferencesDialog, setShowPreferencesDialog] = useState<boolean>( false );
  	
  	// data-management jobs.
	const [sDataManagementJobs, setDataManagementJobs] = useState< JobType[] >( [] );
	const [sJobsLoading, setJobsLoading] = useState< boolean >( false );
	
	const [sCheckForLogin, setCheckForLogin] = useState< boolean >( false );
				
	// build a list of tokens that need to be exchanged.
	var TOKENS_REQUIRED:	{
				apiName: string,
				tokenID: string,
				}[] =	[
						{
						apiName: 'gateway-backend-api',
						tokenID: 'gateway_backend_token'
						},
						{
						apiName: 'data-management-api',
						tokenID: 'data_management_token'
						},
						{
						apiName: 'site-capabilities-api',
						tokenID: 'site_capabilities_token'
						}
					];
	
	//	-------------------------------------------------
	//
	//	Build a list of parameters for the visualisation
	//	tools.
	//
	//	The parameter list is an array that consists of:
	//
	//	1. a code, i.e. 'aladin'
	//	2. a set of launch parameters. if these parameters are not null then the
	//		tool will automatically be launched without user intervention.
	//	3. a set of other parameters required by the tool.
	//
	//	-------------------------------------------------
	
	function buildVisualisationParams()
	{
	
		//	-------------------------------------------------
		//
		//	parameters for Aladin.
		//
		//	-------------------------------------------------
		
		var aladinLaunchParams:	{
						position:	{
								ra: number,
								dec: number,
								obs_publisher_did: string 
								} | null
						} | null = null;
		const aladinParams:	{
					viewDataFromAladinFootprint: any,
					updateState: any
					} | null =	{
							viewDataFromAladinFootprint: viewDataFromAladinFootprint,
							updateState: updateState
							};
		
		//	-------------------------------------------------
		//
		//	parameters for SkyServer.
		//
		//	-------------------------------------------------

		const skyserverParams:	{
					updateState: any
					} | null =	{
							updateState: updateState
							};
		
		//	-------------------------------------------------
		//
		//	combine the parameters into a single data structure.
		//
		//	-------------------------------------------------
	
		const params:	{
				code: string,
				launchParams: {} | null,
				params: {} | null
				}[] =	[
						{
							code:		'aladin',
							launchParams:	aladinLaunchParams,
							params:	aladinParams
						},
						{
							code:		'skyserver',
							launchParams:	null,
							params:	skyserverParams
						}
					];
		
		// return something.
		return params;
	
	} // buildVisualisationParams
  	
	//	-------------------------------------------------
	//
	//	check if a user is logged in, and determine
	//	which tokens we've managed to retrieve.
	//
	//	-------------------------------------------------
	
	async function checkForCurrentLogin()
	{
						
		// call the /whoami end point to get a list of currently held OIDC tokens.
		try
		{

  			// call the /whoami endpoint here.
			const apiResult = await fetch( APIPrefix() + '/v1/whoami',
								{
								headers: {'Content-Type': 'application/json'},
								credentials: 'include'
								} );

			if (apiResult.status === 200)
			{
			
				const returnedJson = await apiResult.json();
				console.log( '/whoami (checkForCurrentLogin)' );
				console.log( returnedJson );
				
				// do we have a current login?
				if (returnedJson.logged_in === true)
				{
				
					// set the login status.
					console.log( "setLoggedIn true (checkForCurrentLogin)" );
					setLoggedIn( true );
				
					// store the username.
					if (returnedJson.name !== undefined)
						setUsername( returnedJson.name );
				
					// get true or false for each token type.
					setSiteCapabilitiesTokenObtained( returnedJson.site_capabilities_token_obtained );
					setDataManagementTokenObtained( returnedJson.data_management_token_obtained );
					setGatewayBackendTokenObtained( returnedJson.gateway_backend_token_obtained );
					
					if (returnedJson.gateway_backend_token_obtained === true)
					{
					
						// load the user settings.
						const loadSettingsTask: CurrentTask = { taskType: TaskType.LOAD_SETTINGS };
						const taskDirective: TaskDirective =	{
											refreshDirective: RefreshDirective.REFRESH,
											retryAfterRefresh: true
											};
						taskExecutor(	{
								currentTask: loadSettingsTask,
								taskDirective: taskDirective
								} );
		  	
				  		// get the list of data-management jobs.
						const getDMJobsTask: CurrentTask = { taskType: TaskType.GET_DATA_MANAGEMENT_JOBS };
						const noRefreshTaskDirective: TaskDirective =	{
												refreshDirective: RefreshDirective.NO_REFRESH
												}
						taskExecutor(	{
								currentTask: getDMJobsTask,
								taskDirective: noRefreshTaskDirective
								} );
					  	
					}
				
				}
				else
				{
				
					// set the login status.
					console.log( "setLoggedIn false (checkForCurrentLogin)" );
					setLoggedIn( false );
					
				}

				// set loading token state.
				setLoadingToken( false );
						
			}
		
      		}
      		catch (e)
      		{
      			console.log(e);
		}
	
	} // checkForCurrentLogin
  	
	//	-------------------------------------------------
	//
	//	keep working down the list of tokens that need exchanging
	//
	//	-------------------------------------------------
  	
  	function exchangeNextToken( args:	{
						currentTask: CurrentTask,
						tokensRequired:	{
									apiName: string,
									tokenID: string
									}[],
						tokensObtained:	{
									tokenID: string
									}[]
						} )
  	{
				
		// exchange some more tokens.
		if (args.tokensRequired.length > 0)
			exchangeToken(	{
					currentTask: args.currentTask,
					tokensRequired: args.tokensRequired,
					tokensObtained: args.tokensObtained
					} );
		else
		{
		
			var gatewayBackendTokenFound: boolean = false;
			var siteCapabilitiesTokenFound: boolean = false;
			var dataManagementTokenFound: boolean = false;
			for ( var i: number = 0; i < args.tokensObtained.length; i++ )
			{
				if (args.tokensObtained[ i ].tokenID === 'gateway_backend_token')
					gatewayBackendTokenFound = true;
				if (args.tokensObtained[ i ].tokenID === 'data_management_token')
					dataManagementTokenFound = true;
				if (args.tokensObtained[ i ].tokenID === 'site_capabilities_token')
					siteCapabilitiesTokenFound = true;
			}
		
			// now we need to finish doing whatever we were doing before a token was found to be expired.
			// here we must set the refresh directive to NO_REFRESH, because the token should already be
			// fresh and we don't want an infinite loop.
			if (args.currentTask.taskType !== TaskType.NONE)
			{
				const taskDirective: TaskDirective =	{
									refreshDirective: RefreshDirective.NO_REFRESH
									};
				taskExecutor(	{
						currentTask: args.currentTask,
						taskDirective: taskDirective
						} );
			}
			
			// set the current task to NONE.
			const currentTask: CurrentTask = { taskType: TaskType.NONE };
			setCurrentTask( currentTask );
						
			// update the token refresh count to stop the refresh process.
			setTokenRefreshCount( sRefreshTokens );
			
			console.log( "token-exchange-has-completed" );
						
			// increment the individual token refresh count, but only if the tokens were successfully refreshed (otherwise we might get stuck
			// in an endless loop).
			if (gatewayBackendTokenFound === true)
			{
				setGatewayBackendTokenRefreshCount( sGatewayBackendTokenRefreshCount + 1 );
				setGatewayBackendTokenObtained( true );
			}
			if (siteCapabilitiesTokenFound === true)
			{
				setSiteCapabilitiesTokenRefreshCount( sSiteCapabilitiesTokenRefreshCount + 1 );
				setSiteCapabilitiesTokenObtained( true );
			}
			if (dataManagementTokenFound === true)
			{
				setDataManagementTokenRefreshCount( sDataManagementTokenRefreshCount + 1 );
				setDataManagementTokenObtained( true );
			}

			// loading token states.
			setLoadingToken( false );
						
		}
  	
  	} // exchangeNextToken
  	
	//	-------------------------------------------------
	//
	//	exchanges the current live token for another token
	//
	//	-------------------------------------------------
  	
  	async function exchangeToken( args:	{
  						currentTask: CurrentTask,
  						tokensRequired:	{
  									apiName: string,
  									tokenID: string
  									}[],
  						tokensObtained:	{
  									tokenID: string
  									}[]
  						} )
  	{
  	
  		// get the information on this token.
  		const tokenRequired:	{
  					apiName: string,
  					tokenID: string
  					} = args.tokensRequired[ 0 ];
  		var tokensRequired:	{
  					apiName: string,
  					tokenID: string
  					}[] = args.tokensRequired;
  		tokensRequired.splice( 0, 1 );
  		var tokensObtained:	{
  					tokenID: string
  					}[] = args.tokensObtained;
  	
		try
		{

  			// call the /exchange_token endpoint here.
			const apiResult = await fetch( APIPrefix() + '/v1/exchange_token?api_name=' + tokenRequired.apiName  + '&token_id=' + tokenRequired.tokenID,
								{headers: {'Content-Type': 'application/json'}, credentials: 'include'} );

			if (apiResult.status === 200)
			{

				// loading token state.
				setLoadingToken( false );
			
				const returnedJson = await apiResult.json();
				console.log( returnedJson );
				
				// was the auth token returned OK ?
				if (returnedJson.token_status_code === 200)
				{
					
					tokensObtained.push(	{
								tokenID: tokenRequired.tokenID
								} );
				
					console.log( tokenRequired.tokenID + ' has been obtained' );
				
				}
					
				// get the user settings, and retrieve the backend token.
				if (tokenRequired.apiName === 'gateway-backend-api')
				{

					// load user settings and retrieve the backend token.
					const taskDirective: TaskDirective =	{
										refreshDirective: RefreshDirective.NO_REFRESH
										};
					loadUserSettings( { taskDirective: taskDirective } );
					retrieveBackendToken();

				}
						
			}
				
			// exchange some more tokens.
			exchangeNextToken(	{
						currentTask: args.currentTask,
						tokensRequired: tokensRequired,
						tokensObtained: tokensObtained
						} );
		
      		}
      		catch (e)
      		{
      			console.log(e);
		}
  	
  	} // exchangeToken
	
	//	-------------------------------------------------
	//
	//	Finds the index of the user's language in the
	//	language dropdown box.
	//
	//	-------------------------------------------------
					
	function findLanguageIndex( args:	{
						language: string | null
						} )
	{
	
		var languageIndex: number = 0;
		if (args.language !== null)
		{
		
			// get the language index.
			var languageIndex: number = LANGUAGE_OPTIONS.findIndex( (element) => element.value === args.language );
			if (languageIndex === -1)
				languageIndex = 0;
		
		}
		
		// return something.
		return languageIndex;
	
	} // findLanguageIndex

	//	-------------------------------------------------
	//
	//	Call the /auth_token endpoint to get the access token.
	//
	//	-------------------------------------------------
  	
  	async function getAuthToken( args:	{
  						code: string,
  						redirectURI: string
  						} )
  	{
  	
		try
		{
			
  			// call the /token endpoint here. we can get a 200 if we retrieved a token, or 204 (no content) if we're already logged in.
			const apiResult = await fetch( APIPrefix() + '/v1/auth_token?code=' + args.code + '&redirect_uri=' + args.redirectURI,
								{headers: {'Content-Type': 'application/json'}, credentials: 'include'} );

			if (apiResult.status === 200)
			{
			
				const returnedJson = await apiResult.json();
				console.log( '/auth_token' );
				console.log( returnedJson );
				
				// was the auth token returned OK ? 200 means we were logged in, a 204 means we were already logged in so we don't
				// need to do anything else here.
				if (returnedJson.token_status_code === 200)
				{
				
					// build a list of tokens that need to be exchanged.
					var tokensRequired:	{
								apiName: string,
								tokenID: string
								}[] = TOKENS_REQUIRED;
					var tokensObtained:	{
								tokenID: string
								}[] = [];

					// set loading state.
					setLoadingToken( true );
								
					// now exhange the other required tokens.
					const currentTask: CurrentTask = { taskType: TaskType.NONE };
					exchangeNextToken(	{
								currentTask: currentTask,
								tokensRequired: tokensRequired,
								tokensObtained: tokensObtained
								} );
					console.log("tokens required: " + JSON.stringify(tokensRequired) + "tokens obtained " + tokensObtained);
					// set login state.
					console.log( "setLoggedIn true (getAuthToken)" );
					setLoggedIn( true );
					setSessionValid( true );
						
					// get the username.
					getUsername();
				
				}
						
			}
		
      		}
      		catch (e)
      		{
      			console.log(e);
		}
  		
  	} // getAuthToken
	
	//	-------------------------------------------------
	//
	//	Gets the user's language from the browser
	//	settings. If the language is hyphenated, i.e. en-US,
	//	then we will only use the bit from before the hyphen.
	//
	//	-------------------------------------------------
					
	function getBrowserDefaultLanguage( args:	{
							defaultLang: string
							} )
	{
	
		// get the browser's language.
		var browserLanguage = navigator.language;
		
		var language: string = args.defaultLang;
		if (browserLanguage !== '')
		{
		
			// extract the characters before the first '-'.
			const hyphenPos = browserLanguage.indexOf( '-' );
			if (hyphenPos > -1)
				language = browserLanguage.slice( 0, hyphenPos );
			else
				language = browserLanguage;
		
		}
			
		// return something.
		return language;
		
	} // getBrowserDefaultLanguage

	//	------------------------------------------------------------
	//
	//	An asynchronous function that loads a list of data-management
	//	API jobs.
	//
	//	------------------------------------------------------------
  	
  	async function getDataManagementJobs(	args:	{
  							taskDirective: TaskDirective
  							} )
  	{
  	
		setJobsLoading( true );
	
		try
		{

			var urlCommand: string = APIPrefix() + '/v1/data_management/list_jobs';

			try
			{
				
				const apiResult = await fetch( urlCommand,	{
										headers:	{
												'Content-Type': 'application/json'
												},
										credentials:	'include'
										} );
										
				if (apiResult.status === 200)
				{
				
					const returnedJson = await apiResult.json();

					// get jobs list.
					var jobsList: JobType[] = [];
					if (returnedJson.jobs_list !== undefined)
						jobsList = returnedJson.jobs_list;
						
					// remove jobs with status 'NOT FOUND'.
					for ( var i = jobsList.length - 1; i >= 0; i-- )
						if (jobsList[ i ].status === 'NOT FOUND')
							jobsList.splice( i, 1 );
					
					// update the state with the list of returned jobs.
					setDataManagementJobs( jobsList );
					
				}
				
				// if the return code is 401 then either the data-management token or the gateway-backend
				// token has expired. we should renew them.
				if (apiResult.status === 401)
				{
					console.log( "getDataManagementJobs 401: attempting refresh" );
					const currentTask: CurrentTask = { taskType: TaskType.GET_DATA_MANAGEMENT_JOBS };
					refreshTokens(	{
							currentTask: currentTask,
							taskDirective: args.taskDirective
							} );
				}
				
			}
			catch (e)
			{
				setDataManagementJobs( [] );
				console.log( e );
			}
		
      		}
      		catch (e)
      		{
      			setDataManagementJobs( [] );
			console.log(e);
		}
		
		// clear loading boolean.
  		setJobsLoading( false );
  	
  	} // getDataManagementJobs

	//	-------------------------------------------------
	//
	//	get the name of the currently logged in user
	//
	//	-------------------------------------------------
	
	async function getUsername()
	{

		try
		{
			
  			// call the /whoami endpoint here.
			const apiResult = await fetch( APIPrefix() + '/v1/whoami',
								{
								headers: {'Content-Type': 'application/json'},
								credentials: 'include'
								} );
			if (apiResult.status === 200)
			{
			
				const returnedJson = await apiResult.json();
				console.log( '/whoami (getUsername)' );
				console.log( returnedJson );
				
				// store the username.
				setUsername( returnedJson.name );
				
				// store the preferred_username
				setPreferredUsername( returnedJson.preferred_username )
						
			}
		
      		}
      		catch (e)
      		{
		}
	
	} // getUsername

	//	-------------------------------------------------
	//
	//	the function to initiate a data-management search
	//	is initially undefined. it will be set when the
	//	data-management component has mounted.
	//
	//	this setup allows one child component (search catalog)
	//	to raise an event that triggers a function to run
	//	in another child component (data management).
	//
	//	-------------------------------------------------
	
	// the function runs in the child component (data-management), but is held here in state.
	const [sInitiateDataManagementSearch, setInitiateDataManagementSearch] = useState<any>( undefined );
	
	// this function is called by other child components when they want to initiate a data-management search.
	function initiateDataManagementSearchEvent( args:	{
								namespace: string,
								filename: string
								} )
	{
	
		// kick off the data-management search.
		sInitiateDataManagementSearch(	{
							namespace: args.namespace,
							filename: args.filename
							} );
							
		// switch to the data-management tab.
		setSelectedTool( ToolType.DATA_MANAGEMENT );
	
	} // initiateDataManagementSearchEvent
			
	// this function is called when the data-management component renders, and sets the function in state.
	const initiateDataManagementSearchUpdate = (newFunction: any) =>
	{
	
		setInitiateDataManagementSearch( () => newFunction );
		
	} // initiateDataManagementSearchUpdate

	//	-------------------------------------------------
	//
	//	launch a Jupyter notebook when the user selects one from
	//	the compute-resource search
	//
	//	-------------------------------------------------
	const fetchXsrfToken = async (url: string ) => {
		try {
		  const response = await fetch( url + (url.slice( -1 ) === '/' ? '' : '/') + 'hub/login' , {
			credentials: "include"
		  });
	
		  if (response.ok) {
			const htmlText = await response.text();
			//console.log("HTML " + htmlText);
			const parser = new DOMParser();
			const doc = parser.parseFromString(htmlText, 'text/html');
			const xsrfInput = doc.querySelector('input[name="_xsrf"]') as HTMLInputElement;
	
			console.log("XSRF " + xsrfInput.value);
			if (xsrfInput) {
			  setXsrfToken(xsrfInput.value);
			}
		  }
		} catch (error) {
		  console.error('Failed to fetch XSRF token:', error);
		}
	  };

	const loginUserJupyterHub = async (url : string) => {
		try{
		  const formData = new FormData();

		  if (gatewayToken){
			  formData.append('token', gatewayToken || '');
			  formData.append('_xsrf', xsrfToken || '');

			  const response = await fetch( url + (url.slice( -1 ) === '/' ? '' : '/') + 'hub/login' , {
				  method: 'POST',
				  credentials: "include",
				  headers: {
				  'Content-Type': 'application/x-www-form-urlencoded',
				  },
				  body: new URLSearchParams(formData as any).toString(),
			  });

			  if (response.ok){
				  setJupyterURL( url + (url.slice( -1 ) === '/' ? '' : '/') + 'hub/spawn' );
				  var jupyterRender: number = sJupyterRender;
				  setJupyterRender( jupyterRender + 1 );
				  setSelectedTool( ToolType.NOTEBOOK );
			  }
			  else {
				  console.error("Login Failed :(", response.statusText);
			  }
		  }
		}catch (error){
		  console.error("Error occurred while Login :(", error);
		}
	  }
  	
  	const launchNotebook = async( args: { url: string } ) =>
  	{
		try{
			// update the url and the render count (we need this to force a re-render when the user clicks 'launch' again).
			if (args.url !== undefined)
			{
				//TODO: Firstly we need clear any tokens stored for JHub. Assuming the user launchd the notebook before
				console.log("Username >>>>>>>>>>" + sPreferredUsername);
				if(sPreferredUsername){
					//const jupyter_username = JSON.parse(session_username);
					const response = await fetch( args.url + (args.url.slice( -1 ) === '/' ? '' : '/') + 'user/' + sPreferredUsername + '/logout' , {
						credentials: "include"
					  });
				
					  if (response.ok) {
						// In order to re-SSO the user to JupyterHubs, we need to extract the _xsrf token
						setJupyterBaseURL(args.url);
						await fetchXsrfToken(args.url);
					  }
				}
			}

		}catch (error){
		console.error("Error occurred while launching notebook :(", error);
		}
  			
  	} // launchNotebook

	  useEffect(() => {
		// Submit the form when xsrfToken and access token is set
		console.log("GW Token>>>>>>>>>>> " + gatewayToken);
		if (xsrfToken && gatewayToken) {
		  loginUserJupyterHub(sJupyterBaseURL);
		}
	  }, [xsrfToken, gatewayToken]);

	//	-------------------------------------------------
	//
	//	retrieve backend token
	//
	//	-------------------------------------------------
	async function retrieveBackendToken()
	{
		console.log( 'retrieveBackendToken() fires' );
		try
		{
		
			// call the /get_tokens endpoint here.
			const apiResult = await fetch(APIPrefix() + '/v1/get_tokens',
				{
				   headers: {'Content-Type': 'application/json'},
				   credentials: 'include'
				} );

			if(apiResult.status === 200)
			{
				const returnedJson = await apiResult.json();
				
				// set backend_gateway_token
                setgatewayToken(returnedJson.gateway_backend_token);
			
			}

		}
		catch (e)
		{
		}

	} // retrieveBackendToken

	//	-------------------------------------------------
	//
	//	load and apply the user settings
	//
	//	-------------------------------------------------
  	
  	async function loadUserSettings(	args:	{
  							taskDirective: TaskDirective
  							} )
  	{

		console.log( 'loadUserSettings() fires' );
		try
		{

  			// call the /get_preferences endpoint here.
			const apiResult = await fetch( APIPrefix() + '/v1/get_preferences',
								{
								headers: {'Content-Type': 'application/json'},
								credentials: 'include'
								} );
			if (apiResult.status === 200)
			{

				const returnedJson = await apiResult.json();
				
				// store the theme and language.
				setTheme( returnedJson.darkMode === true ? "dark" : "light" );
				
				if (returnedJson.language !== "")
				{
				
					// change the language.
					//i18n.changeLanguage( returnedJson.language );
					setLanguage( returnedJson.language );
					
					// update the language index.
					var languageIndex: number = findLanguageIndex( { language: returnedJson.language } );
					setLanguageIndex( languageIndex );
					
				}
					
			}
			
			// if we get a 401 then the token has expired. Renew tokens.
			if (apiResult.status === 401)
			{
				console.log( "loadUserSettings 401: attempting refresh" );
				const currentTask: CurrentTask = { taskType: TaskType.LOAD_SETTINGS };
				refreshTokens(	{
						currentTask: currentTask,
						taskDirective: args.taskDirective
						} );
			}
		
      		}
      		catch (e)
      		{
		}
  	
  	} // loadUserSettings

	//	------------------------------------------------------------
	//
	//	Call the API to log out by removing the session cookies
	//
	//	------------------------------------------------------------
  	
  	async function logout()
  	{
  	
  	
  		// remove cookies, and update state variables.
  		console.log( "setLoggedIn false (logout)" );
		setLoggedIn( false );
		setGatewayBackendTokenObtained( false );
		setSiteCapabilitiesTokenObtained( false );
		setDataManagementTokenObtained( false );
		setSessionValid( undefined );

		var urlCommand: string = APIPrefix() + '/v1/logout';

		try
		{
			
			const apiResult = await fetch( urlCommand,	{
									method: 'PUT',
									headers:	{
											'Accept': 'application/json',
											'Content-Type': 'application/json',
											},
									credentials: 'include'
									} );
									
		}
      		catch (e)
      		{
		}
  	
  	} // logout

	//	------------------------------------------------------------
	//
	//	Call the API to move data to a storage area.
	//
	//	------------------------------------------------------------
  	
  	async function moveDataToStorage(	args:	{
  							settings: MoveDataToStorage,
  							taskDirective: TaskDirective
  							} )
  	{

		var urlCommand: string = APIPrefix() + '/v1/data_management/move_data?';

		// token.
		urlCommand = urlCommand +	'to_storage_area_uuid=' + args.settings.toStorageAreaUUID + '&' +
						'lifetime=' + args.settings.lifetime.toString();

		try
		{
			
			const apiResult = await fetch( urlCommand,	{
									method: 'POST',
									headers:	{
											'Accept': 'application/json',
											'Content-Type': 'application/json'
											},
									credentials: 'include',
									body: JSON.stringify( args.settings.filesToMove )
									} );
			if (apiResult.status === 201)
			{
			
				const returnedJson = await apiResult.json();

				// get job ID.
				var jobID: string = '';
				if (returnedJson.job_id !== undefined)
					jobID = returnedJson.job_id;
				
				// update the state with the job ID.
				console.log( "Submitted job id:" );
				console.log( jobID );
				
				// refresh the data-management jobs panel.
				const currentTask: CurrentTask = { taskType: TaskType.GET_DATA_MANAGEMENT_JOBS };
				taskExecutor(	{
						currentTask: currentTask,
						taskDirective: args.taskDirective
						} );
				
			}
			
			// if the return code is 401 then the tokens have expired. we should renew them.
			if (apiResult.status === 401)
			{
				console.log( "moveDataToStorage 401: attempting refresh" );
				const currentTask: CurrentTask =	{
									taskType: TaskType.MOVE_DATA_TO_STORAGE,
									moveDataToStorage: args.settings
									};
				refreshTokens(	{
						currentTask: currentTask,
						taskDirective: args.taskDirective
						} );
			}
			
			// if the return code is 403 then we don't have a token. either we're
			// not logged in, or we're not authorised to the data-management API.
			
		}
		catch (e)
		{
			console.log( e );
		}
  	
  	} // moveDataToStorage

	//	-------------------------------------------------
	//
	//	handles button click events on the main form.
	//
	//	-------------------------------------------------

	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
	{
	
		event.preventDefault();

		const button: HTMLButtonElement = event.currentTarget;
		
		// if one of the project buttons has been pressed then update the currently selected project.
		if (button.name.length > 7)
			if (button.name.slice( 0, 7 ) === "project")
			
				setSelectedProject( button.name );
				
		// check if the clicked button is one of the tools shown across the top of the page.
		for ( var i = 0; i < TOOLS.length; i++ )
			if (button.name === TOOLS[ i ].name)
				setSelectedTool( TOOLS[ i ].toolType );
			
		// if the login button is clicked then display or hide the pop-up login box.
		if (button.name === "btnLogin")
			
			// connect to SKA IAM and OIDC.
			redirectToLoginURL();
			
		// if the user account button is clicked then display or hide the dropdown menu.
		if (button.name === "btnUserAccount" && sUserDropdownMenuDisplayed === false)
			setUserDropdownMenuDisplayed( true );
		else
			setUserDropdownMenuDisplayed( false );
			
		// logout button from the user control dropdown menu.
		if (button.name === "btnLogout")
		{
		
			setUserDropdownMenuDisplayed( false );
			
			// log out of the backend.
			logout();
			
		}
		
		// OIDC token button from the user control dropdown menu.
		if (button.name === "btnOIDCToken")
			setSelectedTool( ToolType.OIDC_TOKENS );
			
		// user preferences dialog
		if (button.name === "btnPreferences")
		{
			if (sShowPreferencesDialog === true)
				setShowPreferencesDialog( false );
			else
				setShowPreferencesDialog( true );
		}
		
		// language menu.
		if (button.name === "lstLanguage" && sLanguageDropdownMenuDisplayed === false)
			setLanguageDropdownMenuDisplayed( true );
		else
			setLanguageDropdownMenuDisplayed( false );
			
		// update the current language.
		if (button.name.length > 12)
			if (button.name.slice( 0, 12 ) === "btnLanguage_")
			{

				// get the rest of the text.
				var language: string = button.name.slice( 12 - button.name.length );

				// update the language index.
				var languageIndex: number = findLanguageIndex( { language: language } );
				setLanguageIndex( languageIndex );
		
				// update the user-preferences database.
				if (sLoggedIn === true)
				{
					const settings: UserSettings = { language: language };
					const currentTask: CurrentTask =	{
										taskType: TaskType.UPDATE_SETTINGS,
										settings: settings
										};
					const taskDirective: TaskDirective =	{
										refreshDirective: RefreshDirective.REFRESH_IF_LOGGED_IN,
										retryAfterRefresh: true
										};
					taskExecutor(	{
							currentTask: currentTask,
							taskDirective: taskDirective
							} );
				}
				
				// change the language.
				//i18n.changeLanguage( language );
				setLanguage( language );
			
			}
		
	}; // onClickHandler

	//	-------------------------------------------------
	//
	//	calls the IAM /login endpoint to get the login url, and redirect the browser.
	//
	//	-------------------------------------------------
  	
  	async function redirectToLoginURL()
  	{
		
  		// interface for parsing the returned JSON from /login.
  		interface uriResult
  		{
  			//authorization_uri: string;
  			login_url: string;
  		}
	
		try
		{
	
			// call the /login endpoint of the SKA IAM.
			const apiResult = await fetch( APIPrefix() + '/v1/login?redirect_uri=' + sRedirectURI,
								{
								headers: {'Content-Type': 'application/json'}
								} );
			const loginURI = await apiResult.text();
			
			// parse the returned values.
			let obj: uriResult = JSON.parse( loginURI );
			
			// redirect the browser to the login uri.
			window.location.href = obj.login_url;
			
      		}
      		catch (e)
      		{
		}
  	
  	} // redirectToLoginURL
  	
	//	-------------------------------------------------
	//
	//	refresh the authentication token
	//
	//	-------------------------------------------------
  	
  	async function refreshAuthToken( args: { currentTask: CurrentTask } )
  	{
	  	
		try
		{

  			// call the /refresh_token endpoint here.
			const apiResult = await fetch( APIPrefix() + '/v1/refresh_token',
								{
								headers: {'Content-Type': 'application/json'},
								credentials: 'include'
								} );

			if (apiResult.status === 200)
			{
			
				const returnedJson = await apiResult.json();
				console.log( '/refresh_token' );
				console.log( returnedJson );
				
				// was the auth token returned OK ?
				if (returnedJson.token_status_code === 200)
					console.log( "auth token has been refreshed" );
				
				// build a list of tokens that need to be exchanged.
				var tokensRequired:	{
							apiName: string,
							tokenID: string
							}[] = TOKENS_REQUIRED;
				var tokensObtained:	{
							tokenID: string
							}[] = [];
							
				// now exhange the other required tokens.
				exchangeNextToken(	{
							currentTask: args.currentTask,
							tokensRequired: tokensRequired,
							tokensObtained: tokensObtained
							} );
						
			}
		
      		}
      		catch (e)
      		{
      			console.log(e);
		}
  	
  	} // refreshAuthToken

	//	------------------------------------------------------------
	//
	//	Start the process of refreshing the tokens
	//
	//	The function checks if we're logged in before attempting to
	//	refresh, but if the logged-in state has just been set then
	//	it may not have been updated yet and the refresh will fail.
	//	Therefore, if the calling function knows that the user is
	//	logged in, it can force the refresh to happen by setting
	//	the optional logged-in parameter to true.
	//
	//	------------------------------------------------------------
  	
  	function refreshTokens(	args:	{
  						currentTask?: CurrentTask,
  						taskDirective: TaskDirective
  						} )
  	{
  	
  		console.log( "App => refreshTokens() fires" );
  		
		// check if this refresh is forced, or if we're logged in.
		if (args.taskDirective.refreshDirective === RefreshDirective.REFRESH || (args.taskDirective.refreshDirective === RefreshDirective.REFRESH_IF_LOGGED_IN && sLoggedIn === true))
		{
  		
			console.log( "token-exchange-starting: incrementing sTokenRefreshCount " + sTokenRefreshCount.toString() );

			// set the current task, and increment the token refresh counter to start the process.
			if (args.currentTask !== undefined && args.taskDirective.retryAfterRefresh === true)
				setCurrentTask( args.currentTask );
			setRefreshTokens( sTokenRefreshCount + 1 );
			
		}
  	
  	} // refreshTokens

	//	------------------------------------------------------------
	//
	//	Use the backend API to add or update a user-access token.
	//
	//	------------------------------------------------------------
  	
  	async function saveUserToken(	args:	{
  						userServiceToken: UserServiceToken,
  						taskDirective: TaskDirective
  						} )
  	{

		try
		{
			

			var url = APIPrefix() + '/v1/set_user_token' +
					'?service_id=' + encodeURIComponent( args.userServiceToken.serviceID ) +
					'&using_token=' + (args.userServiceToken.usingToken === true ? 'Y' : 'N')
			if (args.userServiceToken.usingToken === true)
				url = url +
					'&username=' + encodeURIComponent( args.userServiceToken.username ) +
					'&user_token=' + encodeURIComponent( args.userServiceToken.userToken );

			// call the /set_user_token endpoint of the backend API.
			const apiResult = await fetch( url,	{
								method: 'PUT',
								headers: {'Content-Type': 'application/json'},
								credentials: 'include'
								} );

			// if we get a 401 then the token has expired. Renew tokens.
			if (apiResult.status === 401)
			{
				console.log( "saveUserToken 401: attempting refresh" );
				const currentTask: CurrentTask =	{
									taskType: TaskType.UPDATE_USER_SERVICE_TOKEN,
									userServiceToken: args.userServiceToken
									};
				refreshTokens(	{
						currentTask: currentTask,
						taskDirective: args.taskDirective
						} );
			}
			
		}
		catch (e)
		{
		}
  	
  	} // saveUserToken

	//	-------------------------------------------------
	//
	//	apply new settings to the database for this user.
	//
	//	-------------------------------------------------

	async function setPreferences(	args:	{
						settings: UserSettings,
						taskDirective: TaskDirective
						} )
	{
		
		try
		{

			var withParams: boolean = false;
			var url = APIPrefix() + '/v1/set_preferences';
			if (args.settings.darkMode !== undefined)
			{
				if (withParams === false)
				{
					url = url + "?";
					withParams = true;
				}
				else
					url = url + "&";
				url = url + "dark_mode=" + (args.settings.darkMode === true ? "T" : "F");
			}
			if (args.settings.language !== undefined)
			{
				if (withParams === false)
				{
					url = url + "?";
					withParams = true;
				}
				else
					url = url + "&";
				url = url + "language=" + args.settings.language;
			}

			// call the /set_preferences end point of the backend API.
			const apiResult = await fetch( url,	{
								method: 'PUT',
								headers: {'Content-Type': 'application/json'},
								credentials: 'include'
								} );
					
			// if we get a 401 then the token has expired. Renew tokens.
			if (apiResult.status === 401)
			{
				console.log( "setPreferences 401: attempting refresh" );
				const currentTask: CurrentTask =	{
									taskType: TaskType.UPDATE_SETTINGS,
									settings: args.settings
									};
				refreshTokens(	{
						currentTask: currentTask,
						taskDirective: args.taskDirective
						} );
			}

		}
		catch (e)
		{
		}

	} // setPreferences
				
	//	------------------------------------------------------------
	//
	//	carry out a defined task that requires a valid access token.
	//	if the token has expired then it will be refreshed and the
	//	task will be attempted again.
	//
	//	------------------------------------------------------------
  	
  	async function taskExecutor(	args:	{
						currentTask: CurrentTask,
						taskDirective: TaskDirective
						} )
  	{
  	
  		console.log( "taskExecutor fires - taskType " + TaskType[ args.currentTask.taskType ] + " - refreshDirective " + RefreshDirective[ args.taskDirective.refreshDirective ] );
					
		// finish loading the user settings.
		if (args.currentTask.taskType === TaskType.LOAD_SETTINGS)
			loadUserSettings(	{
						taskDirective: args.taskDirective
						} );
			
		// finish updating the user settings.
		if (args.currentTask.taskType === TaskType.UPDATE_SETTINGS && args.currentTask.settings !== undefined)
			setPreferences(	{
						settings: args.currentTask.settings,
						taskDirective: args.taskDirective
						} );
						
		// finish updating user-service token.
		if (args.currentTask.taskType === TaskType.UPDATE_USER_SERVICE_TOKEN && args.currentTask.userServiceToken !== undefined)
			saveUserToken(	{
					userServiceToken: args.currentTask.userServiceToken,
					taskDirective: args.taskDirective
					} );
						
		// finish moving data files to storage.
		if (args.currentTask.taskType === TaskType.MOVE_DATA_TO_STORAGE && args.currentTask.moveDataToStorage !== undefined)
			moveDataToStorage(	{
						settings: args.currentTask.moveDataToStorage,
						taskDirective: args.taskDirective
						} );
						
		// get data-management jobs.
		if (args.currentTask.taskType == TaskType.GET_DATA_MANAGEMENT_JOBS)
			getDataManagementJobs(	{
						taskDirective: args.taskDirective
						} );
  	
  	} // taskExecutor
	
	//	-------------------------------------------------
	//
	//	Functions that allows the child components to
	//	update the state of the App function.
	//
	//	-------------------------------------------------
	
	function updateState( args:	{
					resetSearchPosition?: boolean,
					setShowPreferencesDialog?: boolean
					} )
	{
	
		// reset the state of the object id because a new result set has been
		// created on the Search catalogue tab.
		if (args.resetSearchPosition !== undefined)
			if (args.resetSearchPosition === true)
				setSearchPosition( [] );
				
		// update the display status of the preferences dialog box.
		if (args.setShowPreferencesDialog !== undefined)
			setShowPreferencesDialog( args.setShowPreferencesDialog );
	
	} // updateState

	//	-------------------------------------------------
	//
	//	The user has chosen clicked on a dataset in Aladin
	//	and selected to view the data on the Search
	//	catalogue tab.
	//
	//	-------------------------------------------------
  	
  	const viewDataFromAladinFootprint = function( args:	{
  								position: { id: string, ra: number, dec: number }[]
  								} )
  	{
  	
  		console.log( "search-catalog.tsx - obs_publisher_did:" );
  		console.log( args.position );
  		
  		// store the object ID.
  		setSearchPosition( args.position );
  		
  		// switch the tab to the search results page.
  		setSelectedTool( ToolType.SEARCH_CATALOG );
  	
  	} // viewDataFromAladinFootprint
	
	// define hook for changing the language when the state variable changes.
	useEffect	( () =>
			{
			
				// change the language to the that stored within state.
				i18n.changeLanguage( sLanguage );
			
			}, [sLanguage]
			);
  	
  	useEffect	( () =>
		  	{
			
				// get the re-direct address from the URL.
				const redirect: string = GetURL();
				setRedirectURI( redirect );
		  	
		  		console.log( "useEffect [] - code: " + authorisationCode + ", sLoggedIn: " + sLoggedIn );
		  		console.log( "redirect: " + redirect );
		  		console.log( "api prefix: " + APIPrefix() );
	
				// check if we have a current login, and update the state variables. don't do this if there's an
				// authorisation code waiting to be processed.
				if (authorisationCode === null)
					setCheckForLogin( true );
				
				// attempt to retrieve an access token from the API using the code from the url parameters.
				if (authorisationCode !== null)
				{
				
					getAuthToken( { code: authorisationCode, redirectURI: redirect } );
				
					// remove the query params from the url.
					window.history.replaceState( null, "", redirect );
				
				}
				
				console.log( 'NODE_ENV:' );
				console.log( process.env.NODE_ENV );
				
			}, []
			);
				
		// define a hook that checks if the user is currently logged in. we only do this when the CheckForLogin flag
		// changes to true in order that the code runs once only.
		useEffect	( () =>
				{
				
					if (sCheckForLogin === true)
					{
						console.log( "useEffect [sCheckForLogin] - sCheckForLogin: " + sCheckForLogin );
						checkForCurrentLogin();
					}
				
				}, [sCheckForLogin]
				);
				
		// define a hook that refreshes the token when the token refresh count is incremented.
		useEffect	( () =>
				{
				
					// refresh the tokens, and ensure that the current task is completed.
					if (sRefreshTokens > sTokenRefreshCount)
						refreshAuthToken( { currentTask: sCurrentTask } );
				
				}, [sRefreshTokens]
				);
  	
	//	-------------------------------------------------
	//
	//	Session Toast
	//
	//	-------------------------------------------------
	async function checkUserSession() {
		try{
			const session_valid =  await hasSessionExpired();
			// update the state that holds if the current login session is valid.
			setSessionValid( session_valid );
		}
		catch(e){
		}
	}

	useEffect(() => {
		console.log("User session hook>>>>>>>>>>>>>>>>");
		const refreshInterval = setInterval(checkUserSession, 60000);
		return () => clearInterval(refreshInterval);
	}, []);

	useEffect(() =>{
		if (sSessionValid === false && sLoggedIn === true)
		{
			console.log("User session expired>>>>>>>>>>>>>>>>");
			toast( <SessionToast onLogin = {redirectToLoginURL} /> );
			// Log the user out
			logout();
		}
	}, [sSessionValid]);

	return (
		<Suspense fallback = "loading">
		<div className = "page-body" data-theme = {sTheme}>
		
			<div className = "header">
			
				<div className = "header-icon">
					<div className = "ska-logo-container-dark"><img src = {srcLogo} alt = "" width = "190px" /></div>
					<div className = "ska-logo-container-light"><img src = {srcLightLogo} alt = "" width = "190px" /></div>
				</div>
									
				{/* the horizontal icon bar contains all the tools that the user might want to use. */}
				<div className = "horizontal-icon-bar">
				
					{
					TOOLS.map
					(
						( item, index ) =>
						(
							<Tool	key = {index.toString() + t( item.text ) + (sLoadingToken ? "T" : "F")}
								name = {item.name}
								icon = {item.icon}
								text = {t( item.text )}
								onClick = {onClickHandler}
								selected = {sSelectedTool === item.toolType}
								loading =	{
										sLoadingToken === true &&
										(
										item.needSiteCapabilities === true ||
										item.needDataManagement === true ||
										item.needLoggedIn === true ||
										item.needJupyterURL === true
										)
										}
								disabled =	{
										(item.needSiteCapabilities === true && sSiteCapabilitiesTokenObtained !== true) ||
										(item.needDataManagement === true && sDataManagementTokenObtained !== true) ||
										(item.needLoggedIn === true && sLoggedIn !== true) ||
										(item.needJupyterURL === true && sJupyterURL === '')
										} />
						)
					)
					}
							
					{/*<Tool	name = "btnVMs"
							icon = {monitorIcon}
							text = {t("VMs")}
							onClick = {onClickHandler}
							selected = {sSelectedTool === ToolType.VIRTUAL_MACHINE}
							disabled = {true} />*/}

					<div className = "flex-expander"></div>
								
					<div className = "horizontal-icon-bar-flex-column">
					
						<div style = {{ flex: '0 0 40px' }}></div>
						<UserControl	key = {sUsername + (sLoggedIn ? 'T' : 'F')}
								loggedIn = {sLoggedIn}
								username = {sUsername}
								buttonHandler = {onClickHandler}
								userDropdownMenuDisplayed = {sUserDropdownMenuDisplayed}
								setUserDropdownMenuDisplayed = {setUserDropdownMenuDisplayed} />
								
						<div style =	{{
								position: 'relative',
								margin: '0px 10px 0px 10px',
								display: 'flex',
								flexDirection: 'column',
								flex: '0 0 40px'
								}}>
							<button	className = "horizontal-icon-bar-language-holder"
								name = "lstLanguage"
								type = "button"
								onClick = {onClickHandler}
								data-menu-displayed = {sLanguageDropdownMenuDisplayed ? "T" : "F"}>
								<div className = "language-text">{sLanguageIndex >= 0 ? LANGUAGE_OPTIONS[ sLanguageIndex ].label : ''}</div>
								<div className = "flex-10px"></div>
								<img src = {sLanguageIndex >= 0 ? LANGUAGE_OPTIONS[ sLanguageIndex ].icon : undefined} alt = "" width = "30" height = "16" />
								<div className = "flex-5px"></div>
							</button>
							<LanguageDropdownMenu	sLanguageDropdownMenuDisplayed = {sLanguageDropdownMenuDisplayed}
										setLanguageDropdownMenuDisplayed = {setLanguageDropdownMenuDisplayed}
										buttonHandler = {onClickHandler} />
						</div>
						
					</div>
							
				</div>
			
			</div>
			<div className = "white-horizontal-separator"></div>
			
			{/* the project space is everything below the tool bar */}
			<div style = {projectSpace}>
			
				{/* we have a project bar down the left-hand side so the user can switch between projects */}
				<div className = "project-bar">
					<div className = "project-button-container">
						<ProjectButton	name = "projectOne"
								icon = {planetIcon}
								onClick = {onClickHandler}
								selected = {sSelectedProject === "projectOne" || sSelectedProject === ""}
								projectName = {t("Project One")}
								description = "Project description goes here"
								pi = "PI: A.N. Other" />
						<ProjectButton	name = "projectTwo"
								icon = {dishIcon}
								onClick = {onClickHandler}
								selected = {sSelectedProject === "projectTwo"}
								projectName = {t("Project Two")}
								description = "Project description goes here"
								pi = "PI: A.N. Other" />
						<ProjectButton	name = "projectThree"
								icon = {galaxyIcon}
								onClick = {onClickHandler}
								selected = {sSelectedProject === "projectThree"}
								projectName = {t("Project Three")}
								description = "Project description goes here"
								pi = "PI: A.N. Other" />
					</div>
					<div className = "flex-expander"></div>
				</div>
			    	<div className = "white-vertical-separator"></div>
						
		
				{/* The home tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.HOME ? "T" : "F"}>
					<Home></Home>
				</div>
			
				{/* The search catalog tab for data discovery */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.SEARCH_CATALOG ? "T" : "F"}>
					<SearchCatalog	searchPosition = {sSearchPosition}
							setSearchPosition = {setSearchPosition}
							updateState = {updateState}
							tabVisible = {sSelectedTool === ToolType.SEARCH_CATALOG}
							initiateDataManagementSearchEvent = {initiateDataManagementSearchEvent}
							dataStagingAvailable = {sDataManagementTokenObtained} />
				</div>
				
				{/* For debugging only - display the data on the currently logged in user */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.OIDC_TOKENS ? "T" : "F"}>
					{
						(sSelectedTool === ToolType.OIDC_TOKENS) &&
						<UserControlOIDCToken	key = {sSiteCapabilitiesTokenRefreshCount} />
					}
				</div>
				
				{/* The search compute tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.SEARCH_COMPUTE ? "T" : "F"}>
					<SearchCompute	tokenRefreshCount = {sSiteCapabilitiesTokenRefreshCount}
							tokenObtained = {sSiteCapabilitiesTokenObtained}
							taskExecutor = {taskExecutor}
							renewTokens = {refreshTokens}
							launchNotebook = {launchNotebook}
							tabVisible = {sSelectedTool === ToolType.SEARCH_COMPUTE} />
				</div>
				
				{/* The data management tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.DATA_MANAGEMENT ? "T" : "F"}>
					<DataManagement	dataManagementTokenRefreshCount = {sDataManagementTokenRefreshCount}
								siteCapabilitiesTokenRefreshCount = {sSiteCapabilitiesTokenRefreshCount}
								gatewayBackendTokenRefreshCount = {sGatewayBackendTokenRefreshCount}
								dataManagementJobs = {sDataManagementJobs}
								renewTokens = {refreshTokens}
								taskExecutor = {taskExecutor}
								jobsLoading = {sJobsLoading}
								tabVisible = {sSelectedTool === ToolType.DATA_MANAGEMENT}
								initiateSearch = {initiateDataManagementSearchUpdate} />
				</div>
				
				{/* The notebook tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.NOTEBOOK ? "T" : "F"}>
					<iframe	key = {sJupyterRender}
							className = "notebook"
							src = {sJupyterURL} />
				</div>
				
				{/* The visualisation tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.VISUALISE_DATA ? "T" : "F"}>
					<Visualisation	params = {buildVisualisationParams()} />
				</div>
				
				{/* The user-management tab */}
				<div	className = "section"
					data-visible = {sSelectedTool === ToolType.USER_MANAGEMENT ? "T" : "F"}>
					<UserManagement	tabVisible = {sSelectedTool === ToolType.USER_MANAGEMENT} />
				</div>
				
				{/* If none of the above then we display an empty page */}
				<div	className = "section"
					data-visible = {TOOLS.findIndex( element => element.toolType === sSelectedTool ) === -1 &&
										sSelectedTool !== ToolType.OIDC_TOKENS ? "T" : "F"} >
					<div className = "empty-window"></div>
				</div>
			    	
			</div>
			{/*<div className="white-horizontal-separator"></div>*/}
						
			{/* dim the whole page background if a modal form is being displayed */}
		    	{
		    		(sShowPreferencesDialog === true) &&
		    		<div	style = {{ width: '100%', height: '100%', backgroundColor: 'black', opacity: '0.3', position: 'absolute', top: '0px', left: '0px', border: 'none', display: 'flex', flexDirection: 'row', zIndex: '0' }} />
		    	}
		    	
			{/* display the user preferences dialog (if the state flag is set) */}
		    	{
		    		(sShowPreferencesDialog === true) &&
	    			<Preferences	theme = {sTheme}
	    					setTheme = {setTheme}
	    					taskExecutor = {taskExecutor}
	    					updateState = {updateState} />
		    	}
			{/* Session Toast */}
			<ToastContainer 
			   position="top-right"
			   autoClose={false}
			   hideProgressBar
			   closeOnClick={false}
			   pauseOnHover
			   draggable={false}
			   theme={sTheme}
			/>

		</div>
        	</Suspense>
	);
	
} // App

export default App;
